import React from 'react'
import { css } from '@emotion/core'
import { highlight } from '../theme'
import PostList from '../components/postList'
import Layout from "../layouts/mainLayout";
import {BlogContext} from "../context/mainContext";
import {graphql} from "gatsby";

function HeroPage(props) {
  const { pageContext: { content} } = props
  const { context: { theme }} = props
  const { data: { appsync: { getPage: { posts: {items} }}}} = props
  return (
    <div css={container} className="hero-page-content">
      <div dangerouslySetInnerHTML={{ __html: content }} />
        <Layout noPadding>
            <PostList
                fixedWidthImages
                posts={items}
                highlight={highlight}
                context={{theme}}
            />
        </Layout>
    </div>

 )
}

export default function HeroPageWithContext(props) {
  return (
      <BlogContext.Consumer>
        {
          context => <HeroPage {...props} context={context} />
        }
      </BlogContext.Consumer>
  )
}

const container = css`
  width: 900px;
  margin: 20px auto;
  @media (max-width: 900px) {
    width: 100%;
    padding: 0px 10px;
  }
`

export const pageQuery = graphql`
  query GetPageById($id: ID!) {
    appsync {
      getPage(id: $id) {
        id
        name
        slug
        content
        components
        published
        posts {
          items {
            id
            title
            description
            summary
            cost
            headCount
            content
            cover_image
            createdAt
            published
            previewEnabled
            categories
            contentType
            updatedAt
          }
        }
      }
    }
  }
`
